import { useEffect } from 'react'

const ModuleScriptHead = (props) => {
  useEffect(() => {
    if (props?.id) {
      const spanTag = document.getElementById(props?.id)
      if (!spanTag) {
        const scriptFragment = document
          .createRange()
          .createContextualFragment(`${props?.headInsert}`)
        document.head.append(scriptFragment)
        const spanFragment = document.createElement('span')
        spanFragment.id = props?.id
        spanFragment.className = 'default_script_id'
        document.body.appendChild(spanFragment)
      }
    }
  }, [])

  return null
}

export default ModuleScriptHead
